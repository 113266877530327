body {
  background-color: #282c34;
  color: white;
  padding: 40px;
  font-family: 'Sans-Serif';
  text-align: center;
}
body * {
  -webkit-text-size-adjust: 100%;
}

:root {
  --page-title-display: block;
  --user-background-color: #e6e6e6;
  --user-text-color: #000000;
  --hu-btnTextSize: var(--textSize);
  --hu-secondaryColor: var(--hu-borderColor);
  --hu-btnColor: var(--hu-primaryColor);
  --hu-disabledColor: var(--hu-borderColor);
  --hu-breakpointSm: 576px;
  --hu-breakpointMd: 768px;
  --hu-breakpointLg: 992px;
  --hu-breakpointXl: 1200px;
  --hu-scale: 1;
  --primary-03: #ffffff;
  --brand-background-color: #6666D1;
  --brand-lighter-background-color: #9898ff;
  --brand-text-color: #FFFFFF;
  --input-text-color: #000000;
  --hu-textColor: #434f58;
  --hu-textSize: 13px;
  --hu-headingSize: 15px;
  --hu-spacingSize: 20px;
  --hu-marginSize: 20px;
  --hu-animationSpeed: 0.3s;
  --hu-headingColor: #434f58;
  --hu-primaryColor: #6666d1;
  --hu-borderColor: #5e6a74;
  --hu-bannerColor: rgba(255, 255, 255, 0.95);
  --hu-bannerOpacity: 0.95;
  --hu-btnTextColor: #ffffff;
  --hu-silverColor: #22c0ef;
  --hu-goldColor: #4493e0;
  --hu-platinumColor: #5b75d6;
}

body {
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-typography-primary-font-family: 'Roboto';
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: 'Roboto Slab';
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: 'Roboto';
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: 'Roboto';
  --e-global-typography-accent-font-weight: 500;
}

body * {
  word-wrap: break-word;
}

body * {
  --flex-direction: initial;
  --flex-wrap: initial;
  --justify-content: initial;
  --align-items: initial;
  --align-content: initial;
  --gap: initial;
  --flex-basis: initial;
  --flex-grow: initial;
  --flex-shrink: initial;
  --order: initial;
  --align-self: initial;
}

body * {
  --swiper-theme-color: #000;
  --swiper-navigation-size: 44px;
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --widgets-spacing: 20px 20px;
}

body {
  text-align: left;
  color: #fff;
  font-size: 25px;
  box-sizing: border-box;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
  padding: 0 0 20px;
}

